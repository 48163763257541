import { create } from "zustand";

import { GetSingleOrderDto } from "@services/fms-api";

interface ActiveOrderStore {
  order?: GetSingleOrderDto;
  setOrder: (activeOrder?: GetSingleOrderDto) => void;
}

export const useActiveOrderStore = create<ActiveOrderStore>((set) => ({
  order: undefined,
  setOrder: (activeOrder?: GetSingleOrderDto) =>
    set(() => ({ order: activeOrder })),
}));
