import { useHandleBlockedNavigation } from "@hooks/customHooks/useHandleBlockedNavigation";
import AddTwoTone from "@mui/icons-material/AddTwoTone";
import DateRangeTwoTone from "@mui/icons-material/DateRangeTwoTone";
import Button from "@mui/material/Button";
import { useCreateOrderStore } from "@store/useCreateOrderStore";
import { useNavigationBlockerStore } from "@store/useNavigationBlockerStore";
import { useOnBeforeLeaveStore } from "@store/useOnBeforeLeaveStore";
import { useOrderNotesStore } from "@store/useOrderNotesStore";
import { useReminderStore } from "@store/useReminderStore";

const CreateButtons = () => {
  const { navigationBlocked, setNavigationStoreValues } =
    useNavigationBlockerStore();
  const { onBeforeLeaveCb, setOnBeforeLeaveCb } = useOnBeforeLeaveStore();

  const { openNewNoteModal } = useOrderNotesStore();
  const { setOpenCreateOrderModal } = useCreateOrderStore();
  const { setOpenNewReminderModal } = useReminderStore();
  const { handleNavigation } = useHandleBlockedNavigation();

  const onCreateOrderClick = () => {
    const callback = () => {
      onBeforeLeaveCb?.();
      setOnBeforeLeaveCb(null);
      handleNavigation();
      setOpenCreateOrderModal(true);
    };

    if (!navigationBlocked) {
      callback();
    } else {
      setNavigationStoreValues({ onLeaveCallback: callback });
    }
  };

  const onCreateReminderClick = () => {
    const callback = () => {
      onBeforeLeaveCb?.();
      setOnBeforeLeaveCb(null);
      handleNavigation();
      setOpenNewReminderModal(true);
    };

    if (openNewNoteModal && navigationBlocked) {
      setNavigationStoreValues({ onLeaveCallback: callback });
    } else {
      callback();
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        sx={{ whiteSpace: "nowrap" }}
        startIcon={<AddTwoTone />}
        onClick={onCreateOrderClick}>
        Create New Order
      </Button>
      <Button
        size="small"
        variant="contained"
        sx={{ whiteSpace: "nowrap" }}
        startIcon={<DateRangeTwoTone />}
        color="info"
        onClick={onCreateReminderClick}>
        Add Reminder
      </Button>
    </>
  );
};

export default CreateButtons;
