import { globalConfig } from "@configuration";
import { QueryKey } from "@enums/FE/QueryKey";
import { ClientInfo } from "@services/rps-api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type Variables = {
  clientId: number;
  includeObys: boolean;
};

export const getClient = async ({
  clientId,
  includeObys,
}: Variables): Promise<ClientInfo> => {
  const config = globalConfig.get();
  const { data } = await axios.get(
    `${config.rpsUrl}Clients/${clientId}?includeObys=${includeObys}`
  );
  return data;
};

export const useFetchClient = ({ clientId, includeObys }: Variables) => {
  const result = useQuery({
    queryKey: [QueryKey.Clients, clientId, includeObys],
    queryFn: () => getClient({ clientId, includeObys }),
    enabled: !!clientId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    clientDetails: result?.data,
    fetchingClientDetails: result?.isLoading,
  };
};
