import FieldTitle from "@components/forms/FieldTitle";
import { FormAutocomplete } from "@components/forms/FormAutocomplete";
import FormDatePickerField from "@components/forms/FormDatePickerField";
import FormInputField from "@components/forms/FormInputField";
import BasicModal from "@components/modals/BasicModal";
import { getDialogStyles } from "@helpers/styles/getDialogStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOrderPermissions } from "@hooks/customHooks/useOrderPermissions";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { useFetchOrder } from "@hooks/orders/useFetchOrder";
import { useSearchByOrderId } from "@hooks/orders/useSearchByOrderId";
import { useSearchByVin } from "@hooks/orders/useSearchByVin";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigationBlockerStore } from "@store/useNavigationBlockerStore";
import { useReminderStore } from "@store/useReminderStore";
import { vinRegexp } from "@validation/helpers";
import { reminderSchema } from "@validation/reminderSchema";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router-dom";

type DefaultValues = {
  orderId: string;
  vin: string;
  userId: number;
  actionDate: string;
  note: string;
};

type Props = {
  title: string;
  defaultValues: DefaultValues;
  onSubmit: (data: FieldValues) => void;
  handleClose: () => void;
  isEditing?: boolean;
  isLoading?: boolean;
};

const ReminderForm: FC<Props> = ({
  title,
  defaultValues,
  onSubmit,
  handleClose,
  isEditing = false,
  isLoading = false,
}) => {
  const params = useParams();
  const { showSnackbarMessage } = useSnackbarMessage();

  const {
    navigationBlocked,
    setNavigationStoreValues,
    resetNavigationStoreValues,
  } = useNavigationBlockerStore();

  const { order } = useFetchOrder(Number(params?.orderId || 0));
  const { userOptions, isFetchingUsers } = useFetchUsers();
  const { canEditOrder } = useOrderPermissions();

  const { setOpenNewReminderModal } = useReminderStore();

  const formMethods = useForm<FieldValues>({
    defaultValues,
    resolver: yupResolver(reminderSchema(isEditing)),
  });

  const orderId = formMethods.watch("orderId");
  const vin = formMethods.watch("vin");
  const note = formMethods.watch("note");

  const [inputOrderId, setInputOrderId] = useState("");
  const [inputVin, setInputVin] = useState("");

  useEffect(() => {
    if (defaultValues.vin) {
      setInputVin(defaultValues.vin);
    }
  }, [defaultValues.vin]);

  const [applyToOrder, setApplyToOrder] = useState(false);

  const applyValuesToOrder = useCallback(() => {
    if (canEditOrder) {
      if (params?.orderId) {
        formMethods.setValue("orderId", params.orderId);
        order && formMethods.setValue("vin", order?.vehicles?.[0]?.vin || "");
      }
      setApplyToOrder((prev) => !prev);
    }
  }, [params?.orderId, order, formMethods, canEditOrder]);

  useEffect(() => {
    canEditOrder && applyValuesToOrder();
  }, [applyValuesToOrder, canEditOrder]);

  useEffect(() => {
    if (orderId !== params?.orderId || order?.vehicles?.[0]?.vin !== vin) {
      setApplyToOrder(false);
    }
  }, [order?.vehicles, orderId, params?.orderId, vin]);

  useEffect(() => {
    if (
      canEditOrder &&
      orderId === params?.orderId &&
      (order?.vehicles || []).some((vehicle) => vehicle?.vin === vin)
    ) {
      setApplyToOrder(true);
    }
  }, [order?.vehicles, orderId, params?.orderId, vin, canEditOrder]);

  const { ordersByOrderId, fetchingOrdersByOrderId } = useSearchByOrderId(
    inputOrderId || ""
  );
  const { ordersByVin, fetchingOrdersByVin } = useSearchByVin(inputVin || "");
  const { order: orderDetails } = useFetchOrder(orderId);

  const [vinOptions, setVinOptions] = useState<string[]>([]);
  const [ordersOptions, setOrdersOptions] = useState<string[]>([]);

  useEffect(() => {
    if (!vin && ordersByOrderId) {
      setOrdersOptions(
        ordersByOrderId?.map((order) => order?.toString() || "")
      );
    }
  }, [ordersByOrderId, vin]);

  useEffect(() => {
    if (ordersByVin && !orderId) {
      setOrdersOptions(
        ordersByVin.map((order) => order?.orderId?.toString() || "")
      );
    }
  }, [vin, ordersByVin, orderId]);

  useEffect(() => {
    if (orderDetails) {
      setVinOptions(
        (orderDetails?.vehicles || []).map((vehicle) => vehicle?.vin || "")
      );
    }
  }, [orderDetails]);

  const onError = () => {
    if (!vin) {
      if (inputVin && !vinRegexp.test(inputVin)) {
        formMethods.setError("vin", { message: "Invalid value" });
      } else {
        formMethods.setError("vin", { message: "Required" });
      }
    }
    showSnackbarMessage(
      "Unable to save reminder. Fill required fields to proceed",
      "error"
    );
  };

  const handleCloseDialog = () => {
    const callback = () => {
      setOpenNewReminderModal(false);
      handleClose();
    };

    if (navigationBlocked) {
      setNavigationStoreValues({ onLeaveCallback: callback });
    } else {
      callback();
    }
  };

  useEffect(() => {
    return resetNavigationStoreValues;
  }, [resetNavigationStoreValues]);

  const shouldBlock =
    !!Object.values(formMethods.watch()).filter((value) => !!value).length &&
    formMethods.formState.isDirty;

  useEffect(() => {
    setNavigationStoreValues({ navigationBlocked: shouldBlock });
  }, [shouldBlock, setNavigationStoreValues]);

  return (
    <BasicModal
      handleClose={handleCloseDialog}
      title={title}
      handleConfirm={onSubmit}
      handleError={onError}
      formMethods={formMethods}
      isLoadingConfirm={isLoading}
      disabledConfirm={!shouldBlock || !note}
      styles={getDialogStyles("28rem")}>
      <FormProvider {...formMethods}>
        <Stack spacing={1}>
          <FieldTitle title="Order Info" />

          <Controller
            name="orderId"
            control={formMethods.control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => {
              return (
                <Autocomplete
                  id={name}
                  value={value}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    formMethods.clearErrors("orderId");
                    if (!newValue) {
                      setInputOrderId("");
                      formMethods.setValue("orderId", "");
                      setVinOptions([]);
                      setOrdersOptions([]);
                      formMethods.setError("orderId", {
                        message: "Required",
                      });
                    }
                  }}
                  inputValue={inputOrderId}
                  onInputChange={(_event, newInputValue) => {
                    setInputOrderId(newInputValue);
                  }}
                  options={ordersOptions}
                  fullWidth
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Order Number"
                        sx={{ margin: "0.5rem 0 1rem 0" }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    );
                  }}
                  loading={
                    !!((vin || inputVin) && fetchingOrdersByVin) ||
                    !!((orderId || inputOrderId) && fetchingOrdersByOrderId)
                  }
                  disabled={isEditing}
                />
              );
            }}
          />

          {!vinOptions.length ? (
            <TextField
              label="VIN"
              value={inputVin}
              fullWidth
              onChange={(e) => {
                const value = e.target.value;
                setInputVin(value);
                if (vinRegexp.test(value)) {
                  formMethods.setValue("vin", value);
                  formMethods.clearErrors("vin");
                } else {
                  formMethods.setError("vin", { message: "Invalid value" });
                }
                if (!value) {
                  setInputVin("");
                  formMethods.setValue("vin", "");
                  setOrdersOptions([]);
                  formMethods.setError("vin", { message: "Required" });
                } else {
                  formMethods.clearErrors("vin");
                }
              }}
              error={!!formMethods.formState.errors?.vin}
              helperText={
                formMethods.formState.errors?.vin &&
                (formMethods.formState.errors?.vin?.message as string)
              }
            />
          ) : (
            <Controller
              name="vin"
              control={formMethods.control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => {
                return (
                  <Autocomplete
                    id={name}
                    value={value}
                    onChange={(_event, newValue) => {
                      onChange(newValue);
                      formMethods.clearErrors("vin");
                      if (!newValue) {
                        setInputVin("");
                        setOrdersOptions([]);
                        formMethods.setError("vin", {
                          message: "Required",
                        });
                      }
                    }}
                    inputValue={inputVin}
                    onInputChange={(_event, newInputValue) => {
                      setInputVin(newInputValue);
                    }}
                    options={vinOptions}
                    fullWidth
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="VIN"
                          error={!!error}
                          helperText={error?.message}
                        />
                      );
                    }}
                    loading={fetchingOrdersByVin}
                  />
                );
              }}
            />
          )}

          {!isEditing && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToOrder}
                  onChange={applyValuesToOrder}
                  disabled={!params?.orderId}
                  disableRipple
                  sx={{ pl: 0 }}
                />
              }
              label="Apply to this order"
              sx={{ m: 0 }}
            />
          )}

          <div>
            <FieldTitle title="Reminder Info" />
            <FormAutocomplete
              fieldName="userId"
              label="Reminder For"
              options={userOptions || []}
              isLoading={isFetchingUsers}
            />
          </div>

          <FormDatePickerField
            fieldName="actionDate"
            fieldText="Action Date"
            disablePast={!isEditing}
          />

          <FormInputField fieldName="note" fieldText="Reminder" lines={5} />
        </Stack>
      </FormProvider>
    </BasicModal>
  );
};

export default ReminderForm;
