import { QueryKey } from "@enums/FE/QueryKey";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getOrdersByOrderId = async (orderId: string): Promise<string[]> => {
  const { data } = await axios.get(`Orders/search-by-number/${orderId}`);

  return data;
};

export const useSearchByOrderId = (orderId: string) => {
  const { data: ordersByOrderId, isLoading: fetchingOrdersByOrderId } =
    useQuery({
      queryKey: [QueryKey.Orders, orderId, "vin"],
      queryFn: () => getOrdersByOrderId(orderId),
      enabled: !!orderId,
    });

  return { ordersByOrderId, fetchingOrdersByOrderId };
};
