import { QueryKey } from "@enums/FE/QueryKey";
import { GetOrderListDto } from "@services/fms-api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getOrdersByVin = async (vin: string): Promise<GetOrderListDto[]> => {
  const { data } = await axios.get(`Orders/search-by-vin/${vin}`);

  return data;
};

export const useSearchByVin = (vin: string) => {
  const { data: ordersByVin, isLoading: fetchingOrdersByVin } = useQuery({
    queryKey: [QueryKey.Orders, vin],
    queryFn: () => getOrdersByVin(vin),
    enabled: !!vin,
  });

  return { ordersByVin, fetchingOrdersByVin };
};
