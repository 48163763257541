type Props = {
  title: string;
  pb?: string;
};

const FieldTitle: React.FC<Props> = ({ title, pb = "0.75rem" }) => {
  return <p style={{ fontWeight: 500, paddingBottom: pb }}>{title}</p>;
};

export default FieldTitle;
