import { QueryKey } from "@enums/FE/QueryKey";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { UpsertReminderDto } from "@services/fms-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const createReminder = (reminder: UpsertReminderDto): Promise<void> =>
  axios.post("Reminders", reminder);

export const useCreateReminder = () => {
  const queryClient = useQueryClient();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: createReminder,
    onSuccess: () => showSnackbarMessage("Reminder added", "success"),
    onError: () => showSnackbarMessage("Unable to create reminder", "error"),
    onSettled: (_data, _error, { orderId }) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Reminders] });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Order, QueryKey.Reminders, orderId],
      });
    },
  });

  return { createReminder: mutate, isCreatingReminder: isPending };
};
