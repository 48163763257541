import { getUserListItem } from "@helpers/getUserListItem";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BasicOption, UserOption } from "@types";
import { FC, RefCallback, forwardRef } from "react";
import {
  Controller,
  FieldValues,
  UseFormClearErrors,
  useFormContext,
} from "react-hook-form";

interface Props {
  fieldName: string;
  label: string;
  options?: BasicOption[];
  isLoading?: boolean;
  handleChange?: (name?: string, value?: number) => void;
  disabled?: boolean;
  margin?: string;
  disabledOption?: number;
  disableClearable?: boolean;
  triggerFields?: string[];
  isTable?: boolean;
  isRequired?: boolean;
  isUsersList?: boolean;
}

interface BasicAutocompleteSelectProps
  extends Omit<Props, "onChange" | "value"> {
  value?: string | number;
  onChange: (data?: string | number | null) => void;
  errorMessage?: string;
  selectRef?: RefCallback<HTMLInputElement>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

const BasicAutocomplete = forwardRef<
  HTMLDivElement,
  BasicAutocompleteSelectProps
>(
  (
    {
      options,
      disabledOption,
      handleChange,
      label,
      isLoading,
      disabled,
      margin,
      value,
      onChange,
      errorMessage,
      selectRef,
      fieldName,
      disableClearable = false,
      clearErrors,
      triggerFields = [],
      isTable = false,
      isRequired = false,
      isUsersList = false,
    },
    ref
  ) => {
    const { userOptions, isFetchingUsers } = useFetchUsers({
      enabled: isUsersList,
    });

    const dropdownOptions = isUsersList ? userOptions || [] : options || [];

    return (
      <Autocomplete
        id={fieldName}
        fullWidth
        disableClearable={disableClearable}
        defaultValue={null}
        options={dropdownOptions}
        value={
          dropdownOptions.find((option) => option?.value === value) ?? null
        }
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={(option) =>
          option?.label || (option as UserOption)?.email || ""
        }
        getOptionDisabled={(option) => option.value === disabledOption}
        onInputChange={(_e, value) => {
          if (!value) {
            onChange(null);
            handleChange?.();
          }
        }}
        onChange={(_, data) => {
          onChange(data?.value ?? null);
          handleChange?.(data?.label, data?.value ?? 0);
          if (errorMessage) {
            clearErrors?.([fieldName, ...triggerFields]);
          }
        }}
        renderInput={({ ...params }) => (
          <TextField
            {...params}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage || ""}
            inputRef={selectRef}
            required={isRequired}
          />
        )}
        renderOption={(props, option) =>
          isUsersList ? (
            getUserListItem(props, option as UserOption)
          ) : (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )
        }
        loading={isUsersList ? isFetchingUsers : isLoading}
        filterSelectedOptions
        disabled={disabled}
        sx={{ margin, pb: errorMessage || isTable ? 0 : 3 }}
        ref={ref}
      />
    );
  }
);

BasicAutocomplete.displayName = "BasicAutocompleteComponent";

const FormAutocomplete: FC<Props> = ({ fieldName, ...rest }) => {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <BasicAutocomplete
          {...rest}
          errorMessage={error?.message}
          value={value}
          onChange={onChange}
          selectRef={ref}
          fieldName={fieldName}
          clearErrors={clearErrors}
        />
      )}
    />
  );
};

export { BasicAutocomplete, FormAutocomplete };
