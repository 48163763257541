import { USAStatesShort } from "@constants";
import { getClient } from "@hooks/rps/clients/useFetchClient";
import moment from "moment";
import * as yup from "yup";

export const Email = yup.string().trim().email("Invalid email");

export const vinRegexp = /^[a-zA-Z0-9]{17}$/;
export const zipRegexp = /^\d{5}$/;
export const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const dateValidation = yup
  .string()
  .test("date", "Invalid date", function (value) {
    return value ? moment(value).isValid() : true;
  })
  .nullable();

export const dateValidationPast = dateValidation.test(
  "past_date_test",
  "Cannot be past date",
  function (value) {
    return moment(value || "").isSameOrAfter(moment().startOf("day"));
  }
);

export const timeValidation = yup
  .string()
  .matches(/^([01]\d|2[0-3]):[0-5]\d:[0-5]\d$/, {
    message: "Time is not valid",
    excludeEmptyString: true,
  })
  .nullable();

export const vinValidation = yup
  .string()
  .trim()
  .matches(vinRegexp, {
    message: "Invalid VIN",
    excludeEmptyString: true,
  })
  .nullable();

export const phoneValidation = yup
  .string()
  .trim()
  .transform((value) => {
    const phoneRegex = /[()\s-]/g;
    return value.replace(phoneRegex, "");
  })
  .matches(/\d{10}/, {
    message: "Invalid phone number",
    excludeEmptyString: true,
  })
  .nullable();

export const phonesValidation = yup
  .array()
  .of(
    yup.object().shape({
      phoneNumber: phoneValidation.test(
        "required",
        "Required",
        function (value) {
          return this.parent?.label || this.parent?.firstName ? !!value : true;
        }
      ),
      label: yup.string().trim().nullable(),
      firstName: yup.string().trim().nullable(),
    })
  )
  .notRequired();

export const emailsValidation = yup
  .array()
  .of(yup.object().shape({ email: Email }))
  .notRequired();

export const stringValidation = yup.string().trim().nullable();

export const stringRequiredValidation = stringValidation.required("Required");

export const numberIntegerValidation = yup
  .number()
  .transform((value) => value ?? 0)
  .typeError("Should be number")
  .integer("Should be integer")
  .moreThan(-1, "Cannot be less than 0")
  .lessThan(2147483648, "Max number exceeded")
  .nullable();

export const numberDecimalValidation = yup
  .number()
  .transform((value) => (value || value === 0 ? value : null))
  .typeError("Should be number")
  .moreThan(-1, "Cannot be less than 0")
  .lessThan(2147483648, "Max number exceeded")
  .test("is-decimal", "Max two decimal pts", (val: any) => {
    if (val != undefined) {
      return /^\d+(\.\d{0,2})?$/.test(val);
    }
    return true;
  })
  .nullable();

export const orderedByValidation = ({ clientId }: { clientId: number }) =>
  numberIntegerValidation
    .test("required", "Required", async function (value) {
      if (clientId) {
        const client = await getClient({ clientId, includeObys: true });
        return client?.orderedBys?.length ? !!value : true;
      } else {
        return true;
      }
    })
    .nullable();

export const dispatcherSchema = yup.object().shape({
  name: stringValidation.test("required", "Required", function (value) {
    return this.parent?.phone ? !!value : true;
  }),
  phone: stringValidation.test("required", "Required", function (value) {
    return this.parent?.name ? !!value : true;
  }),
});

export const stateValidation = yup
  .string()
  .trim()
  .transform(function (value) {
    if (
      !USAStatesShort.some(
        (state) => state.value?.toLowerCase() === value?.toLowerCase()
      )
    ) {
      return "";
    }
    return value;
  });

export const zipValidation = stringRequiredValidation.matches(zipRegexp, {
  message: "ZIP code is not valid",
  excludeEmptyString: true,
});
