import Chip from "@mui/material/Chip";
import { UserOption } from "../types";

export const getUserListItem = (
  props: React.HTMLAttributes<HTMLLIElement>,
  userOption: UserOption
) => (
  <li {...props} key={userOption.value} style={{ display: "block" }}>
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        alignItems: "center",
      }}>
      {userOption?.roleName ? (
        <Chip label={userOption.roleName} size="small" />
      ) : null}
      <p style={{ flex: 1 }}>{userOption?.label || ""}</p>
    </div>

    <p style={{ fontSize: "0.875rem" }}>{userOption.email}</p>
  </li>
);
