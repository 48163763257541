import * as yup from "yup";
import {
  dateValidation,
  dateValidationPast,
  numberIntegerValidation,
  stringRequiredValidation,
  vinValidation,
} from "./helpers";

export const reminderSchema = (isEdit: boolean) =>
  yup.object().shape({
    orderId: numberIntegerValidation
      .moreThan(0, "Required")
      .required("Required"),
    vin: vinValidation.required("Required"),
    userId: numberIntegerValidation
      .moreThan(0, "Required")
      .required("Required"),
    actionDate: !isEdit
      ? dateValidationPast.required("Required")
      : dateValidation.required("Required"),
    note: stringRequiredValidation,
  });
