import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  fieldName: string;
  fieldText?: string;
  margin?: string | number;
  placeholder?: string;
  lines?: number;
  fullWidth?: boolean;
  defaultValue?: any;
  handleChange?: (value?: string) => void;
  disabled?: boolean;
  height?: string;
  startAdornment?: string;
  endAdornment?: string;
  isTable?: boolean;
  isRequired?: boolean;
}

function FormInputField({
  fieldName,
  fieldText = "",
  margin = 0,
  placeholder = "",
  lines,
  fullWidth = true,
  defaultValue = "",
  handleChange,
  disabled = false,
  height,
  startAdornment,
  endAdornment,
  isTable = false,
  isRequired = false,
}: Readonly<Props>) {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: { onChange, onBlur, value, ref, name },
        fieldState: { error },
      }) => {
        return (
          <TextField
            id={fieldName}
            label={fieldText}
            placeholder={placeholder}
            inputRef={ref}
            inputProps={{
              style: {
                height,
                minHeight: lines ? `${16 * lines}px` : undefined,
              },
              min: 0,
              name,
            }}
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start" disableTypography={disabled}>
                  {startAdornment}
                </InputAdornment>
              ) : undefined,
              endAdornment: endAdornment ? (
                <InputAdornment position="end" disableTypography={disabled}>
                  {endAdornment}
                </InputAdornment>
              ) : undefined,
            }}
            InputLabelProps={{ shrink: !!value }}
            fullWidth={fullWidth}
            value={value || defaultValue || ""}
            onChange={(data) => {
              onChange(data);
              handleChange?.(data?.target?.value);
              if (error) {
                clearErrors([fieldName]);
              }
            }}
            onBlur={onBlur}
            multiline={!!lines}
            rows={lines}
            disabled={disabled}
            sx={{ margin, pb: error?.message || isTable ? 0 : 3 }}
            error={Boolean(error)}
            helperText={error?.message || ""}
            required={isRequired}
          />
        );
      }}
    />
  );
}

export default FormInputField;
