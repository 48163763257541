export const getDialogStyles = (width: string) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",

  "& .MuiDialog-paper": {
    m: 0,
    width,
    maxHeight: "100vh",
    height: "100vh",
    borderRadius: 0,
    boxShadow: "none",
  },
});
