import { create } from "zustand";

interface ReminderStore {
  openNewReminderModal: boolean;
  setOpenNewReminderModal: (open: boolean) => void;
}

export const useReminderStore = create<ReminderStore>((set) => ({
  openNewReminderModal: false,
  setOpenNewReminderModal: (open: boolean) =>
    set(() => ({ openNewReminderModal: open })),
}));
