import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions";

type Props = {
  handleClose: (() => void) | null;
  handleConfirm: () => void;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  isLoadingConfirm?: boolean;
  isLoadingCancel?: boolean;
  hideCancelButton?: boolean;
};

const ModalActions: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  disabledConfirm = false,
  disabledCancel = false,
  confirmBtnText = "Confirm",
  cancelBtnText = "Cancel",
  isLoadingConfirm = false,
  isLoadingCancel = false,
  hideCancelButton = false,
}) => {
  return (
    <DialogActions sx={{ px: 3, pb: 2 }}>
      {!hideCancelButton && handleClose ? (
        <LoadingButton
          onClick={handleClose}
          loading={isLoadingCancel}
          disabled={disabledCancel || !handleClose}>
          {cancelBtnText}
        </LoadingButton>
      ) : null}
      <LoadingButton
        variant="contained"
        onClick={handleConfirm}
        disabled={disabledConfirm}
        loading={isLoadingConfirm}>
        {confirmBtnText}
      </LoadingButton>
    </DialogActions>
  );
};

export default ModalActions;
