import { useCreateReminder } from "@hooks/reminders/useCreateReminder";
import { UpsertReminderDto } from "@services/fms-api";
import { useReminderStore } from "@store/useReminderStore";
import ReminderForm from "./ReminderForm";

const defaultValues = {
  orderId: "",
  vin: "",
  userId: 0,
  actionDate: "",
  note: "",
};

const NewReminder = () => {
  const { openNewReminderModal, setOpenNewReminderModal } = useReminderStore();

  const { createReminder, isCreatingReminder } = useCreateReminder();

  const onSubmit = (data: UpsertReminderDto) =>
    createReminder(data, {
      onSuccess: () => setOpenNewReminderModal(false),
    });

  return (
    <>
      {openNewReminderModal ? (
        <ReminderForm
          title="Add Reminder"
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          handleClose={() => setOpenNewReminderModal(false)}
          isLoading={isCreatingReminder}
        />
      ) : null}
    </>
  );
};

export default NewReminder;
