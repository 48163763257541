import { QueryKey } from "@enums/FE/QueryKey";
import { GetSingleOrderDto } from "@services/fms-api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const getOrder = async (orderId: number): Promise<GetSingleOrderDto> => {
  const { data } = await axios.get(`Orders/${orderId}`);
  return data;
};

export const useFetchOrder = (orderId: number) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QueryKey.Order, orderId],
    queryFn: () => getOrder(orderId),
    enabled: !!orderId,
  });

  return { order: data, isFetchingOrder: isLoading, getOrderError: isError };
};
