import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  fieldName: string;
  fieldText?: string;
  margin?: string | number;
  fullWidth?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: string | null;
  maxDate?: string | null;
  disabled?: boolean;
  handleChange?: (date: string | null) => void;
  triggerFields?: string[];
  isTable?: boolean;
}

const FormDatePickerField: React.FC<Props> = ({
  fieldName,
  fieldText = "",
  margin = 0,
  fullWidth = true,
  disablePast = false,
  disableFuture = false,
  minDate,
  maxDate,
  disabled = false,
  handleChange,
  triggerFields = [],
  isTable = false,
}) => {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        return (
          <DatePicker
            value={value ? moment(value) : null}
            onChange={(date) => {
              onChange(date ? date.startOf("day").toISOString() : null);
              handleChange?.(date ? date.startOf("day").toISOString() : null);
              if (error) {
                clearErrors([fieldName, ...triggerFields]);
              }
            }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            timezone="default"
            defaultValue={null}
            minDate={minDate ? moment(minDate) : undefined}
            maxDate={maxDate ? moment(maxDate) : undefined}
            disabled={disabled}
            label={fieldText}
            sx={{ margin, pb: error?.message || isTable ? 0 : 3 }}
            slotProps={{
              textField: {
                fullWidth,
                helperText: error?.message || "",
                error: !!error,
                inputRef: ref,
              },
              openPickerButton: {
                sx: {
                  bgcolor: "#fff",
                  zIndex: 1,
                  p: 0.5,
                  "&:hover": { bgcolor: "#fff", zIndex: 1 },
                },
              },
            }}
          />
        );
      }}
    />
  );
};

export default FormDatePickerField;
